import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ContactForm from '../ContactForm';


export default function SignUp() {

  return(
  <> 
  <div className="sign-up">
    <h1 >Apply for a hotspot and start earning!</h1>
    <p style = {{padding:"1%", maxHeight: "50%", minWidth: "50%"}}> Please submit the following information. Our
     team will reach out to you within 48 hours by email. If you'd prefer a phone call or have any questions, please specify in the 'Message' field</p>
    <div >
      <ContactForm/>
    </div>
    <p style = {{fontSize:18, padding: "1rem"}}> This information is strictly used to determine if you are viable candidate to host a hotspot & contact purposes. We will not sell your data.</p>           
  </div>
  <Footer/>
</>
  );
}  