import React, { useState } from "react";

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message, address, referral, phoneNumber } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      referral: referral.value,
      message: message.value,
      address: address.value,
      phoneNumber: phoneNumber.value
    };
    let response = await fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json(); 
    alert(result.status);
  };
  return (
    <div className = "container">
      <form onSubmit={handleSubmit}>
        <div style = {{display: "flex-inline", flexDirection:"row", gap: "6%"}}> 
          <input className = 'input' id="name" placeholder="Name" required />
          <input className = 'input' type="email" id="email" placeholder="Email address" required />
          <input className = 'input' type="text" id="phoneNumber" placeholder="Phone # (Optional)" />
          <input className = 'input' type="text" id="address" placeholder="Home Address" required />
          <input className = 'input' type="text" id="referral" placeholder="Name of referral (if any)"/>
        </div>
        <div>
          <textarea className = 'input' id="message" placeholder= "Message" required />
        </div>
        <button className = "btn--medium" type="submit">{status}</button>
      </form>
    </div>
  );
};

export default ContactForm;