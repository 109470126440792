import React from 'react';
import '../../App.css';
import Footer from '../Footer';

// otherwise known as how it works

export default function Services() {
  return (
  <>

  <div  className="services">
    <h1 className = "service-header">How it Works</h1>
      <div className = "service-p">
        <div className = "textbox">
          <p>The Helium blockchain is an open-source, public blockchain 
          that was developed to incentivize the creation of a physical, decentralized wireless (IoT) network.
          This network is growing alarmingly fast because instead of building out the network
            themselves, Helium is paying individuals in cryptocurrency to operate these hotspots/miners.
          </p> 
        </div>
        <div className = "textbox">
          <p> Maritime Hotspot is bringing the Helium network to the Maritimes.
             Currently our hotspots are all over Fredericton, Halifax and Moncton.
            Our hotspot hosts are earning 50$ or more each month by contributing to 
            the Helium network with Maritime Hotspot.
          </p>
        </div>
        <div className = "textbox">
          <p>
            It's simple, you contribute to the network, we pay you for it.  
          </p>
        </div>
      </div>

      <h1 className = "service-header">Referral</h1>
      <div className = "service-p">
        <div className = "textbox">
          <p> If you refer someone to become a Hotspot Host, you can earn 50$ as well. Just make sure they include your name in the referral field on their application. 
          </p> 
        </div>
      </div>

      <h1 className = "service-header">Payment:</h1>
      <div className = "service-p">
        <div className = "textbox">
          <p> You can choose to receive your monthly payment via Interac E-transfer. You can provide your e-transfer details to one of our installation experts and you will get paid 50$ for your first month the day installation.</p> 
        </div>
        <div className = "textbox">
        <img className = 'interac' src = "images/Interac_e-Transfer_logo.png"></img> 


        </div>
      </div>
  </div>
  <div>
  <Footer/>
  </div>
  </>
  );
}
