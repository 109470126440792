import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  
  return (
    <div className='cards'>
      <h1>Learn more about Helium and The People's Network</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem 
              link = 'https://explorer.helium.com/'
              src='images/explorer.png'
              text="Use the Helium Explorer to view your area's network coverage!"
              label='External'
            />
            <CardItem
              link = 'https://www.helium.com/use'
              src='images/networks.jpeg'
              text='Thousands of developers and companies rely on the Helium Network for connectivity'
              label='External'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
