import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <HeroSection />
      <div style = {{background: "white", textAlign: "center", padding: 20}}>
        <p>Maritime Hotspot is a company focused on building an IoT network in the Maritimes and delivering passive-income to our customers</p>
      </div>
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
